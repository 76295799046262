import { Container, Heading } from '@chakra-ui/react'

export default function Custom404() {
  return (
    <Container>
      <Heading as="h1" textAlign="center" padding={10}>
        404 - Page Not Found
      </Heading>
      <Heading as="h3" fontSize="4xl" textAlign="center" padding={10}>
        Oh Bananas! We were unable to find any produce as requested.
      </Heading>
    </Container>
  )
}
